import React from "react"
import QueueAnim from "rc-queue-anim"
import "./hero.scss"

import telegram from "../../images/community/telegram.svg"
import discurd from "../../images/community/discurd.svg"
import github from "../../images/community/github.svg"
import message from "../../images/community/message.svg"
import twitter from "../../images/community/twitter.svg"
import communityImg from "../../images/community/community.svg"
import communityMinImg from "../../images/community/community-min.svg"
import { globalLinks } from "../../constants"
import UseIsMobile from "../../hooks/UseIsMobile"
const icons = [
  {
    imgUrl: twitter,
    link: globalLinks.iconLinks.twitter,
  },
  {
    imgUrl: telegram,
    link: globalLinks.iconLinks.telegram,
  },
  {
    imgUrl: github,
    link: globalLinks.iconLinks.github,
  },
  {
    imgUrl: discurd,
    link: globalLinks.iconLinks.discord,
  },
]

export default function Hero() {
  const [isMobile] = UseIsMobile()
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="community-hero">
      <MyQueueAnim duration={2000} type="bottom" key="1">
        <div className="community-hero-title" key="community-hero-title">
          Community
        </div>
      </MyQueueAnim>
      <div className="community-bg">
        <div className="community-body">
          <MyQueueAnim duration={2000} delay={300} type="bottom" key="2">
            <div
              className="community-hero-content"
              key="community-hero-content"
            >
              Celer Network values the community support and feedback. We invite
              you to join us in the community channel!
            </div>
          </MyQueueAnim>
          <MyQueueAnim duration={2000} type="bottom" delay={500} key="2">
            <div className="community-in" key="community-in">
              <div className="text">Community and Governance Forum</div>
              {/* eslint-disable-next-line */}
              <div
                className="community-hero-iconsBlock"
                onClick={() => {
                  window.open("https://forum.celer.network/", "_blank")
                }}
              >
                <img src={message} alt="" />
              </div>
              <div className="text">
                Official news, announcements, and articles, Developer Community
              </div>
              <div className="community-iconsBlock">
                {icons.map((item, index) => (
                  <div className="community-hero-icon" key={index}>
                    <a
                      className="active l-grey"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={item.imgUrl} alt="" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </MyQueueAnim>
        </div>
      </div>
      <div className="bg-img">
        <img src={isMobile ? communityMinImg : communityImg} alt="" />
      </div>
    </div>
  )
}
