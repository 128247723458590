import React, { Component } from "react"
import Layout from "../components/layout"
import Hero from "../components/community/Hero"

export default class career extends Component {
  render() {
    return (
      <Layout>
        <Hero />
      </Layout>
    )
  }
}
